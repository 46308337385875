import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import FragrancesList from '../components/fragrances-list'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const query = graphql`
  query YearTemplateQuery(
    $genderId: PostGraphile_Gender!,
    $imageMain: String!,
    $yearId: Int!,
  ) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: $imageMain }) { ...ImageMain }
    results: postgres {
      year: yearById(id: $yearId) {
        ...Year
        fragrances: fragrancesByYearList(
          filter: { gender: { equalTo: $genderId }}
        ) {
          ...Fragrance
        }
        notes: notesList(
          genderId: $genderId
          first: 5
        ) {
          ...Note
        }
      }
    }
  }
`

export default ({ data }) => {
  const { siteMetadata } = data.site
  const { links, meta } = siteMetadata
  const { entity } = meta
  const { year } = data.results
  const { fragrances, notes } = year
  const imageMain = data.imageMain && data.imageMain.childImageSharp.fixed

  const page = year.name
  const title = `${page} - ${entity.singular} Years`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: 'Years',
      slug: links.years,
    },
    {
      name: page,
      slug: year.slug,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: year.about,
    image: imageMain && imageMain.src,
    keywords,
    name: title,
    thing: year,
    slug: year.slug,
  }

  return (
    <Layout>
      <SEO
        description={year.about}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      {imageMain && (
        <Image
          alt={year.name}
          credit={year.image}
          fixed={imageMain}
        />
      )}

      <Paragraph>
        {year.about}
      </Paragraph>

      <Heading level={2}>{year.name} {entity.plural}</Heading>
      <FragrancesList fragrances={fragrances} />

      <Heading level={2}>{year.name} Notes</Heading>
      <List>
        {notes.map(note => (
          <ListItem key={note.id}>
            <Link href={note.slug}>
              {note.name}
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
